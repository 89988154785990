import React from 'react';
import './BlogPage.css'; // Assuming you have an accompanying CSS file

export const Refund = () => {
  return (
    <div className="blog-page">
      <h1>Cancellation Policy</h1>
      <p>100% of slot confirmation amount is refunded if trip cancelled within 7 days of booking.</p>
      <p>30 days or more before departure: 25% of total cost (Will Be Deducted)</p>
      <p>29 - 20 days before departure: 50% of total cost (Will Be Deducted)</p>
      <p>Less than 19 days before departure: 100% of total cost (Will Be Deducted)</p>
      <p></p>
      
      <h1>Refund Policy</h1>
      <h2> In case of cancellation with in the time frame we provide in cancellation policy</h2>
      <p>
      Accepting a replacement event from us of equivalent or similar standard and price (at the date of the change), if we are able to offer you one. It will be applicable for same season only
      </p>
      <p>
        OR
      </p>
      <p>
      Taking Refund- It shall be initiated within 2 working days of receiving the request and the payment would be credited within 7-10 working days via the same mode as used while making the booking.
      </p>
      <h2>In case cancellation after departure due to "force Majeure"</h2>
      <p>
      After departure of trip if the change or cancellation arises from circumstances amounting to "force majeure" or for any other reason beyond our control dusty terrain shall not be liable to refund the amount already deposited.
      </p>
      <h2>In case of drop out by the participant in between the event</h2>
      <p>
      If you leave the trip after departure or in mid of trip then dusty terrain shall not be liable to make any refund in such situation. The participant would also have to pay additionally for the bike transport to the final destination. As per their itinerary
      </p>
    </div>
  );
};
