import "./Footer.css";
import { Link } from "react-router-dom";

export const Footer = () => {
  const branchLoc =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3847.2195600952487!2d75.12117641437735!3d15.364590962016948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d7356f725b09%3A0xe85a2e8c0e433478!2sOaks%202.0!5e0!3m2!1sen!2sin!4v1633436419361!5m2!1sen!2sin";
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="footer-logo">
              <Link to="/"><span>D</span>usty <span>T</span>errain</Link>
            </div>
            <div className="footer-social">
              <a href="https://www.facebook.com/DustyTerrain"><i className="ri-facebook-fill"></i></a>
              <a href="https://www.instagram.com/dustyterrain/"><i className="ri-instagram-fill"></i></a>
              {/* <a href="https://www.youtube.com/@dustyterrain"><i className="ri-youtube-fill"></i></a> */}
            </div>
          </div>
          <div className="footer-links">
            <div>
              <div className="footer-links-title">Company</div>
              <ul className="footer-links-list">
                <li><a href="/about">About</a></li>
                <li><a href="/terms">Terms & Conditions</a></li>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/refund">Cancellation & Refund</a></li>
              </ul>
            </div>
            <div>
              <div className="footer-links-title">Top Destinations</div>
              <ul className="footer-links-list">
                <li><a href="/packages/leh2leh">Leh</a></li>
                <li><a href="/packages/manali2srinagar">Manali</a></li>
                <li><a href="/packages/srinagartomanali">Srinagar</a></li>
                <li><a href="/packages/spiti">Spiti</a></li>
              </ul>
            </div>
            <div>
              <div className="footer-links-title">Quick Links</div>
              <ul className="footer-links-list">
                <li><a href="/packages">Packages</a></li>
                <li><a href="/blogs">Blogs</a></li>
                <li><a href="/faqs">FAQs</a></li>
                <li><a href="/contact">Contact Us</a></li>
              </ul>
            </div>
            <div>
              <div className="footer-links-title">Contact Details</div>
              <ul className="footer-links-list">
                <li>Senapati Prataprao Gurjar Marg,
<br/>Sector 63, Gurugram <br/>Haryana 122001</li>
                <li>918800509901</li>
                {/* <li><a href="/blogs">Blogs</a></li>
                <li><a href="/faqs">FAQs</a></li>
                <li><a href="/contact">Contact Us</a></li> */}
              </ul>
            </div>
          </div>
          <div className="footer-bottom">
            <p>Copyright © Biker Shed. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};
