import "./PricingTable.css";
import React, { useState, useEffect } from 'react';

export const PricingTable = ({data}) => {
  

  return (
    <div className="pricingTableContainer">
      <form id="rzp_payment_form"></form>
      <table className="pricingTable">
        <thead>
          <tr>
            <th>Package</th>
            <th>Transport</th>
            <th>Price(Per Person)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.package}</td>
              <td>{item.transport}</td>
              <td><span className="original-price-trip">{item.price}</span> <span className="discounted-price-trip">{item.price2}</span></td>
              {/* <td><form id="rzp_payment_form"></form></td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
