import React from 'react';
import Cookies from 'js-cookie';

class CountryBasedRedirect extends React.Component {
  componentDidMount() {
    this.checkUserCountryAndRedirect();
  }

  checkUserCountryAndRedirect = () => {
    const userCountry = Cookies.get('userCountry'); // Get the userCountry cookie
    if (userCountry) {
      this.redirectBasedOnCountry(userCountry);
    } else {
      this.askUserCountry();
    }
  };

  redirectBasedOnCountry = (country) => {
    // Define your URLs for Indian and other versions of the website
    const indiaURL = 'https://dustyterrain.com/';
    const internationalURL = 'https://dustyterrain.com/about';

    if (country === 'India') {
      window.location.href = indiaURL;
    } else {
      window.location.href = internationalURL;
    }
  };

  askUserCountry = () => {
    // For simplicity, using prompt. In production, consider a more user-friendly approach.
    const userCountry = window.prompt('Enter your country (India/other):', 'other');
    Cookies.set('userCountry', userCountry, { expires: 7 }); // Set cookie for 7 days
    this.redirectBasedOnCountry(userCountry);
  };

  render() {
    // Since redirection might take a moment, you could show a loading message or spinner here
    return (
      <div>Redirecting you to the appropriate version of our website...</div>
    );
  }
}

export default CountryBasedRedirect;
