import LehImage from '../../media/about.jpg';
import './PackageIntro.css';
import {Modal} from './../Modal';
import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { useNavigate } from "react-router-dom"; 
// import { useHistory } from "react-router-dom"; 

export const PackageIntro = ({data}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const history = useHistory();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    // When the modal is open, add the 'no-scroll' class to body
    // const rzpPaymentForm = document.getElementById("paypal-container-YJX8T9LRP2238");
    // if (!rzpPaymentForm.hasChildNodes()) {
    //   const script = document.createElement("script");
    //   script.src = "https://www.paypal.com/sdk/js?client-id=BAAUOmTnDDEIkGF6kVHxS63vf7bYwEe543xqSOL0A38C_F-nXLxJpijtENr3aZCM-Cfl-HWBw91HISy-ls&components=hosted-buttons&disable-funding=venmo&currency=USD";
    //   script.async = true;
    //   // script.dataset.payment_button_id = data.payment_id;
    //   rzpPaymentForm.appendChild(script);
    // }
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen]);
  return (
    <div className="tour-highlight">

      <div className="tour-image">
        <img src={data.image} alt="Tour Image" />
      </div>
      <div className="tour-content">
        <h1><span>{data.title}</span><span className='title'>{data.mid}</span><span>{data.end}</span></h1>
        <div className="tour-info">
          {data.tags.map((element, index) => {
            return (
              <div className="info-item"  key={index}>
                <div className="info-head">
                  <i className={element.icon} aria-hidden="true"></i>
                  <h3>{element.key}</h3>
                </div>
                <p>{element.value}</p>
              </div>
            )
          })}
        </div>
        <p className='content-description'>{data.description}</p>
        <div className='alignButton'>
          Book trip @ $ 200 only!
        </div>
      <div className="button-container">
        <button type='button' onClick={event =>  window.location.href=`https://www.paypal.com/ncp/payment/${data.payment_id}`}>Book Now</button>
        {/* <form id="rzp_payment_form"></form> */}
        {/* <div id="paypal-container-YJX8T9LRP2238"></div> */}
       
        <button type='button' onClick={toggleModal}>Request More Info</button>
        <Modal isOpen={isModalOpen} onClose={toggleModal} />
        </div>
      </div>


    </div>
  );
};
