import "./Snackbar.css";

export const Snackbar = () => {
	return (
		<>
			<div className="snackbar">
				<span className="text">
				Book and Avail 10% Discount on Select Packages within the next 24 hrs
				</span>
			</div>
		</>
	);
};
