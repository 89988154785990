import goa from "../media/goa.jpg";
import himachal from "../media/himachalbg.jpg";
import kerala from "../media/keralabg.jpg";
import rajasthan from "../media/rajasthan.jpg";
import LehImage from "../media/dusty/Leh2Leh/bg.jpg";
import SpitiImage from "../media/dusty/Spiti/bg.jpg";
import ManaliImage from "../media/dusty/ManaliSrinagar/bg2.jpg";
// import LehImage from '../media/dusty/Leh2Leh/bg.jpg';
import SrinagarImage from "../media/dusty/ManaliSrinagar/bg.jpg";
import FlightIcon from "@mui/icons-material/Flight";
import TrainIcon from "@mui/icons-material/Train";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

export const allPackageDetails = {
	leh2leh: {
		intro: {
			title: "Embark on an unforgettable journey with our ",
			mid: "Leh to Leh ",
			end: "motorcycle expedition.",
			description:
				"Traverse the awe-inspiring Himalayan peaks, wind through tranquil valleys, and explore centuries-old monasteries scattered along the route. This adventure promises breathtaking vistas at every turn, immersing you in the raw beauty of Ladakh. Feel the thrill of conquering challenging terrain while experiencing the warmth of local hospitality. Whether you're a seasoned rider or a novice adventurer, this odyssey offers an unparalleled experience. Get ready to rev your engines, awaken your senses, and create memories that will last a lifetime on this epic Leh to Leh motorcycle adventure.",
			image: LehImage,
			payment_id: "YJX8T9LRP2238",
			tags: [
				{
					key: "Days",
					value: "7 days, 5 Places",
					icon: "fa fa-calendar",
				},
				{
					key: "Accomodation",
					value: "4 Nights Hotel, 2 Nights Tent",
					icon: "fa fa-building",
				},
				{
					key: "Meals",
					value: "6 Dinner, 6 Breakfast",
					icon: "fa fa-coffee",
				},
				{
					key: "Group Size",
					value: "15-20 People",
					icon: "fa fa-users",
				},
				{
					key: "Transport",
					value: "Bikes, Pickup Truck",
					icon: "fa fa-bus",
				},
				{
					key: "Team",
					value: "Road Captain, Mechanic, Photographer, Support Vehicle Driver",
					icon: "fa fa-user-plus",
				},
			],
		},
		highlights: [
			{
				img: require("../media/dusty/Leh2Leh/turtuk.jpg"),
				title: "Turtuk Village",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Leh2Leh/hunder.jpg"),
				title: "Sandunes and Camel Ride Hunder",
				banner: "Own Expense",
			},
			{
				img: require("../media/dusty/Leh2Leh/pangong.jpg"),
				title: "Pangong Lake",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Leh2Leh/changla.jpg"),
				title: "Changla Pass",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Leh2Leh/thiksey.jpg"),
				title: "Thiksey Monastery",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Leh2Leh/khardungla.jpg"),
				title: "Khardungla Pass",
				banner: "Included",
			},
		],
		itinarary: [
			{
				day: "Day 1",
				title: "Arrival in Leh",
				description:
					"Upon arrival, the rest of the day is yours to relish, recharge, and acclimate to the local climate. It's crucial to adapt to the cold temperatures and high altitude of the area to ensure you're prepared for the thrilling escapades awaiting you in Leh Ladakh. Take this time to adjust and immerse yourself in the unique atmosphere of this breathtaking region.",
				tags: [
					{
						key: "Altitude",
						value: "11562 Feet",
					},
					{
						key: "Meals",
						value: "Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/leh2lehroute.jpg"),
			},
			{
				day: "Day 2",
				title: "Leh Local Sightseeing",
				description:
					"Embark on a thrilling motorcycle expedition through the heart of Leh, discovering its captivating         attractions. Begin your journey at Sangam, where the majestic Zanskar and Indus rivers converge in a mesmerizing display of nature's grandeur. Feel the magnetic pull of Magnetic Hill as you witness the fascinating optical illusion of vehicles seemingly defying gravity. Pay homage at Pathar Sahib, a sacred Sikh shrine surrounded by scenic vistas. Honor the bravery of soldiers at the Hall of Fame, showcasing the rich military history of the region. Ascend to Shanti Stupa for panoramic views and peaceful contemplation before immersing yourself in the vibrant Leh Market. Step back in time at Leh Palace, a majestic architectural marvel, and conclude your adventure at Namgyal Tsemo, an ancient monastery steeped in spirituality and history.",
				tags: [
					{
						key: "Distance",
						value: "90 Km",
					},
					{
						key: "Altitude",
						value: "11562 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/leh2lehroute.jpg"),
			},
			{
				day: "Day 3",
				title: "Leh to Nubra Valley",
				description:
					"Begin your day from your hotel towards the stunning Nubra Valley. Traverse the legendary Khardung-La Pass, renowned as the world's highest navigable road year-round. As you arrive in Nubra Valley, be captivated by its breathtaking landscapes, adorned with expansive white sand deserts. Take a moment to admire the grandeur of Diskit Monastery, if time permits. Delight in the unique experience of a camel ride on the double-humped Bactrian Camels amidst the valley's splendor, including the enchanting dunes of Hunder. Wrap up your day with an unforgettable overnight stay at a pre-arranged Hotel in Nubra Valley, promising magical moments under the starlit sky.",
				tags: [
					{
						key: "Distance",
						value: "130 Km",
					},
					{
						key: "Altitude",
						value: "18380 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/leh2lehroute.jpg"),
			},
			{
				day: "Day 4",
				title: "Nubra to Sumur",
				description:
					"Upon arrival, the rest of the day is yours to relish, recharge, and acclimate to the local climate. It's crucial to adapt to the cold temperatures and high altitude of the area to ensure you're prepared for the thrilling escapades awaiting you in Leh Ladakh. Take this time to adjust and immerse yourself in the unique atmosphere of this breathtaking region.",
				tags: [
					{
						key: "Distance",
						value: "200 Km",
					},
					{
						key: "Altitude",
						value: "12500 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/leh2lehroute.jpg"),
			},
			{
				day: "Day 5",
				title: "Sumur to Pangong",
				description:
					"Depart from your campsite and embark on a journey towards Pangong Lake. Revel in the scenic route leading to this exquisite lake nestled along the Indo-China border. Marvel at the breathtaking vistas of the Shyok River and majestic mountains en route to Pangong Lake. Witness the captivating spectacle of the lake's changing hues, transitioning from shades of blue to green and red as you approach Pangong Tso. Spend the night at a pre-arranged campsite near the lake, ensuring a tranquil and unforgettable experience amidst the beauty of Pangong Lake.",
				tags: [
					{
						key: "Distance",
						value: "230 Km (~140Km Offroading)",
					},
					{
						key: "Altitude",
						value: "14270 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/leh2lehroute.jpg"),
			},
			{
				day: "Day 6",
				title: "Pangong to Leh",
				description:
					"Depart from your campsite and head towards Leh, making a stop at the renowned Chang-la Pass, celebrated as one of the world's highest mountain passes. Along the way, discover iconic sites such as the Changla Baba Temple and the Druk Padma School. Don't miss the opportunity to explore the picturesque Thiksey Monastery and Shey Palace during your travels. After your sightseeing adventure, return your bike at the hotel and unwind with an overnight stay in Leh.",
				tags: [
					{
						key: "Distance",
						value: "200 Km",
					},
					{
						key: "Altitude",
						value: "17700 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner + Happy Hour",
					},
				],
				imageURl:require("../media/dusty/routes/leh2lehroute.jpg"),
			},
			{
				day: "Day 7",
				title: "Departure From Leh",
				description:
					"Pack your bags and check out of your hotel in Leh.",
				tags: [
					{
						key: "Meals",
						value: "Breakfast",
					},
				],
				imageURl:require("../media/dusty/routes/leh2lehroute.jpg"),
			},
		],
		keyInfoTabs: {
			
			inclusions: [
				"A-grade accommodations on double sharing basis.",
				"Meals: Dinner & Breakfast (Starting Day 1 Dinner to Day 7 Breakfast) + watebottles during the trip.",
				"RE Classic/Bullet/Himalayan/ Avenger/Xpulse provided with fuel as per your selected package. You will have to pay Rs 5000 (refundable) as a security deposit for Motorcycle on Day 1 which will be refunded at the end of the trip on successful return of the bike in good condition.",
				"Helmets for Rider and Pillion both, though we recommend to bring your own for comfortable fitting",
				"Bonfire at suitable location",
				"Experienced Road Captain on a vehicle",
				"24x7 Breakdown Assistance (Mechanic & support staff)",
				"Back-Up Vehicle specifically for luggage",
				"Inner Line Permits & Environmental Fees",
				"Oxygen Cylinders in case of emergency",
				"Basic Medical Kit",
			],
			exclusions: [
				"Cost of any spare parts consumed during the ride. Any cost arising due to accident of motorbike.",
				"Lunch, snacks, tolls, travel insurance, riding gears other than Helmet.",
				"Any food, travel and accomodation cost arising due to bad health or natural calamities like landslides, road blocks, bad weather etc.",
				"Any cost arising when itinerary changes due to force majeure",
				"Personal expenses such as table drinks, water bottles, alcohol, snacks, telephone calls, room service, laundry, tips to drivers, guides and hotel staff",
				"Motorcycle security deposit of Rs 5000/- per bike (refundable).",
				"Any entry fees for monasteries and monuments or photo/video cameras.",
				"Towing charges of vehicles if left half way during the ride or during accidental cases",
				"Any payment demanded by a driver to ride your bike for some part of the trip if you are unable to ride the bike",
				"Anything not mentioned in tour inclusions",
			],
			paymentPolicy: [
				"An amount of USD 200/- per person to be deposited for slot confirmation",
				"Balance amount to be deposited 45 days before the departure date.",
				"Failure to adhere to 'Payment Policy' can lead to cancellation of the booking without any prior notice.",
			],
			cancellationPolicy: [
				"100% of slot confirmation amount is refunded if trip cancelled within 7 days of booking.",
				"30 days or more before departure: 25% of total cost (Will Be Deducted)",
				"29 - 20 days before departure: 50% of total cost (Will Be Deducted)",
				"Less than 19 days before departure: 100% of total cost (Will Be Deducted)",
			],
			dates: [
				{
					fromDate: "12\u00A0May\u00A02024",
					fromDateDay: "Sunday",
					toDate: "18\u00A0May\u00A02024",
					toDateDay: "Saturday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,249/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A0999/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A01,249/-",
						},]
				},
				{
					fromDate: "22\u00A0May\u00A02024",
					fromDateDay: "Wednesday",
					toDate: "28\u00A0May\u00A02024",
					toDateDay: "Tuesday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,249/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A0999/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A01,249/-",
						},]
				},
				{
					fromDate: "01\u00A0Jun\u00A02024",
					fromDateDay: "Saturday",
					toDate: "07\u00A0Jun\u00A02024",
					toDateDay: "Friday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,249/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A0999/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A01,249/-",
						},]
				},
				{
					fromDate: "11\u00A0Jun\u00A02024",
					fromDateDay: "Tuesday",
					toDate: "17\u00A0Jun\u00A02024",
					toDateDay: "Monday",
					offPercent: "$\u00A0150\u00A0off",
					saleWord:"Hurry!",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,249/-",
							price2: "$\u00A01,099/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A0999/-",
							price2: "$\u00A0899/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A01,249/-",
							price2: "$\u00A01,099/-",
						},]
				},
				{
					fromDate: "21\u00A0Jun\u00A02024",
					fromDateDay: "Friday",
					toDate: "27\u00A0Jun\u00A02024",
					toDateDay: "Thursday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,249/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A0999/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A01,249/-",
						},]
				},
				{
					fromDate: "01\u00A0Jul\u00A02024",
					fromDateDay: "Monday",
					toDate: "07\u00A0Jul\u00A02024",
					toDateDay: "Sunday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,249/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A0999/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A01,249/-",
						},]
				},
				{
					fromDate: "11\u00A0Jul\u00A02024",
					fromDateDay: "Thursday",
					toDate: "17\u00A0Jul\u00A02024",
					toDateDay: "Wednesday",
					offPercent: "No\u00A0Slots",
					saleWord:"Sold\u00A0Out",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,249/-",
							price2: "$\u00A0999/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A0999/-",
							price2: "$\u00A0749/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A01,249/-",
							price2: "$\u00A0999/-",
						},]
				},
				{
					fromDate: "21\u00A0Jul\u00A02024",
					fromDateDay: "Sunday",
					toDate: "27\u00A0Jul\u00A02024",
					toDateDay: "Saturday",
					offPercent: "No\u00A0Slots",
					saleWord:"Sold\u00A0Out",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,249/-",
							price2: "$\u00A0999/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A0999/-",
							price2: "$\u00A0749/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A01,249/-",
							price2: "$\u00A0999/-",
						},]
				},
				
			],
			reachUs: {
				city: "Leh",
				description:
					"Navigating the breathtaking landscapes of Leh, nestled in the heart of the Indian Himalayas, is a journey in itself. Here's your guide on the various ways to reach this enchanting destination: ",
				options: [
					{
						icon: (
							<FlightIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Flight (Most Convenient)",
						description:
							"Leh Kushok Bakula Rimpochee Airport connects the region with major cities like Delhi, Mumbai, and Srinagar. Flights offer a convenient and time-saving option, especially for those traveling from distant locations.",
					},
					{
						icon: (
							<TrainIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Train",
						description:
							"While Leh doesn't have a railway station, the nearest major railhead is Jammu Tawi Railway Station in Jammu. From there, you can opt for a road journey via bus or taxi, but this route involves a long and arduous journey.",
					},
					{
						icon: (
							<DirectionsCarIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Car",
						description:
							"Driving to Leh is an adventure in itself, offering breathtaking views along the way. The most popular route is via Srinagar-Leh Highway or Manali-Leh Highway, both of which offer stunning vistas but require careful planning due to challenging terrain and high-altitude passes.",
					},
					{
						icon: (
							<DirectionsBusIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Bus",
						description:
							"State-run and private buses operate between Leh and several cities in Jammu and Kashmir, including Srinagar and Jammu. Additionally, there's a scenic bus route from Manali to Leh, which is famous for its stunning views and challenging terrain. ",
					},
				],
			},
			packingList: [
				{
					item: "Documents",
					details: [
						"4 passport size photographs.",
						"4 Copies of Photo ID proof. (Pan Card is not Valid ID proof)",
						"Valid driving License (Only for rider)",
						"Bike / Car papers",
					],
				},
				{
					item: "Riding Gear",
					details: [
						"Own Full Face Helmet(Recommended)/ Otherwise provided by us",
						"3 Layered Riding Jacket(Recommended)/ Elbow Pads(Available on Rent)",
						"Riding Pants(Recommended) / Knee Pads( Available on Rent)",
						"Riding Gloves(Available on Rent)",
					],
				},
				{
					item: "Shoes and Backpack",
					details: [
						"Riding Shoes(Recommended)/Trekking Shoes",
						"Rucksack Bag(Max 60L)",
					],
				},
				{
					item: "Warm Layers and Clothes",
					details: [
						"Padded Jackets",
						"Windcheater ",
						"Rain coat",
						"Fleece",
						"3-4 Jeans /3-4 Track pants",
						"5 to 6 T-shirt ",
						"2-3 pairs of thermal.",
						"Towels",
						"3-4 Pair of Cotton Socks, 3-4 Woolen socks",
						"Woolen Hand Gloves,Warm head cover, ear covers & Forehead covers ",
					],
				},
				{
					item: "Accessories",
					details: [
						"Sunglasses",
						"Water Bottle",
						"Multi-Plug",
						"Plastic Bags ",
						"Slippers",
					],
				},
				{
					item: "Toiletries",
					details: [
						"Toothbrush & Paste",
						"Toilet Soap/ Hand sanitizer.",
						"Tissue Papers ",
						"Face wash",
						"Moisturizer",
						"Talc powder",
						"Sunscreen",
						"Lip Balm",
						"Comb/ Brush",
					],
				},
				{
					item: "Medicines",
					details: [
						"Ensure you bring along your personal medical kit to address any potential ailments during your journey. While we do provide basic medications for emergencies, having your own supplies ensures you're prepared for any medical needs that may arise.",
					],
				},
			],
		},
		faqs: [
			{
				question: "What vehicles are provided for luggage carriers?",
				answer: "We offer Mahindra Bolero, Imperio, Scorpio Getaway, and iSuzu for luggage carriers.",
			},
			{
				question: "What riding gear is essential for the Ladakh trip?",
				answer: "Essential riding gear includes a riding jacket, riding pants, boots, gloves, and a full-face helmet. Refer the packing list for complete details",
			},
			{
				question: "Is it necessary to carry thermal wear?",
				answer: "Yes, Ladakh experiences extremely cold temperatures, and carrying thermal wear is recommended as temperatures can drop below freezing.",
			},
			{
				question: "What original documents must be carried?",
				answer: "Original documents required are Covid Vaccination Certificate, Driving Licence, Aadhar Card, and for self-bikers - RC, Insurance, and Pollution Certificate.",
			},
			{
				question: "Is there a risk of oxygen shortage during the trip?",
				answer: `Yes, riding at altitudes above 10,000 feet poses a risk of difficulty in breathing due to low atmospheric oxygen levels. The primary cause of AMS (Acute Mountain Sickness) is dehydration, often exacerbated by riders not consuming enough water. To mitigate AMS, follow these steps: 
                    1. Stay hydrated by drinking water mixed with Electral/ORS/Glucose.
                    2. Avoid pushing yourself too hard; take it slow to acclimate.
                    3. If feeling dizzy or uncomfortable, stop and take Diamox, which begins working within an hour.
                    4. If experiencing dizziness, seek oxygen assistance from the road captain.`,
			},
			{
				question: "I want to bring my own bike, can I come?",
				answer: "Yes you can bring your own bike. Select the appropriate package. Please note that due to the laws of Leh Union, you are only allowed to bring a bike that you or your immediate family members own. You will have to provide proof of ownership and relationship to the family member."
			}
		],
	},
	manali2leh: {
		intro: {
			title: "Embark on an unforgettable journey with our ",
			mid: "Manali to Leh ",
			end: "motorcycle expedition.",
			description:
				"Traverse the awe-inspiring Himalayan peaks, wind through tranquil valleys, and explore centuries-old monasteries scattered along the route. This adventure promises breathtaking vistas at every turn, immersing you in the raw beauty of Ladakh. Feel the thrill of conquering challenging terrain while experiencing the warmth of local hospitality. Whether you're a seasoned rider or a novice adventurer, this odyssey offers an unparalleled experience. Get ready to rev your engines, awaken your senses, and create memories that will last a lifetime on this epic Leh to Leh motorcycle adventure.",
			image: LehImage,
			tags: [
				{
					key: "Days",
					value: "7 days, 5 Places",
					icon: "fa fa-calendar",
				},
				{
					key: "Accomodation",
					value: "4 Nights Hotel, 2 Nights Tent",
					icon: "fa fa-building",
				},
				{
					key: "Meals",
					value: "6 Dinner, 6 Breakfast",
					icon: "fa fa-coffee",
				},
				{
					key: "Group Size",
					value: "15-20 People",
					icon: "fa fa-bus",
				},
				{
					key: "Transport",
					value: "Bikes, Pickup Truck",
					icon: "fa -fa-bus",
				},
				{
					key: "Team",
					value: "Road Captain, Mechanic, Photographer, Support Vehicle Driver",
					icon: "fa fa-user-plus",
				},
			],
		},
		highlights: [
			{
				img: LehImage,
				title: "Turtuk Village",
				banner: "Included",
			},
			{
				img: LehImage,
				title: "Sandunes and Camel Ride Hunder",
				banner: "Own Expense",
			},
			{
				img: LehImage,
				title: "Pangong Lake - 3 Idiots View",
				banner: "3 Idiots View",
			},
			{
				img: LehImage,
				title: "Changla Pass",
				banner: "Included",
			},
			{
				img: LehImage,
				title: "Thiksey Monastery",
				banner: "Included",
			},
			{
				img: LehImage,
				title: "Khardungla Pass",
				banner: "Included",
			},
		],
		itinarary: [
			{
				day: "Day 1",
				title: "Arrival in Leh",
				description:
					"Upon arrival, the rest of the day is yours to relish, recharge, and acclimate to the local climate. It's crucial to adapt to the cold temperatures and high altitude of the area to ensure you're prepared for the thrilling escapades awaiting you in Leh Ladakh. Take this time to adjust and immerse yourself in the unique atmosphere of this breathtaking region.",
				tags: [
					{
						key: "Altitude",
						value: "11562 Feet",
					},
					{
						key: "Meals",
						value: "Dinner",
					},
				],
			},
			{
				day: "Day 2",
				title: "Leh Local Sightseeing",
				description:
					"Embark on a thrilling motorcycle expedition through the heart of Leh, discovering its captivating         attractions. Begin your journey at Sangam, where the majestic Zanskar and Indus rivers converge in a mesmerizing display of nature's grandeur. Feel the magnetic pull of Magnetic Hill as you witness the fascinating optical illusion of vehicles seemingly defying gravity. Pay homage at Pathar Sahib, a sacred Sikh shrine surrounded by scenic vistas. Honor the bravery of soldiers at the Hall of Fame, showcasing the rich military history of the region. Ascend to Shanti Stupa for panoramic views and peaceful contemplation before immersing yourself in the vibrant Leh Market. Step back in time at Leh Palace, a majestic architectural marvel, and conclude your adventure at Namgyal Tsemo, an ancient monastery steeped in spirituality and history.",
				tags: [
					{
						key: "Distance",
						value: "90 Km",
					},
					{
						key: "Altitude",
						value: "11562 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
			},
			{
				day: "Day 3",
				title: "Leh to Nubra Valley",
				description:
					"Begin your day from your hotel towards the stunning Nubra Valley. Traverse the legendary Khardung-La Pass, renowned as the world's highest navigable road year-round. As you arrive in Nubra Valley, be captivated by its breathtaking landscapes, adorned with expansive white sand deserts. Take a moment to admire the grandeur of Diskit Monastery, if time permits. Delight in the unique experience of a camel ride on the double-humped Bactrian Camels amidst the valley's splendor, including the enchanting dunes of Hunder. Wrap up your day with an unforgettable overnight stay at a pre-arranged Hotel in Nubra Valley, promising magical moments under the starlit sky.",
				tags: [
					{
						key: "Distance",
						value: "130 Km",
					},
					{
						key: "Altitude",
						value: "18380 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
			},
			{
				day: "Day 4",
				title: "Nubra to Sumur",
				description:
					"Upon arrival, the rest of the day is yours to relish, recharge, and acclimate to the local climate. It's crucial to adapt to the cold temperatures and high altitude of the area to ensure you're prepared for the thrilling escapades awaiting you in Leh Ladakh. Take this time to adjust and immerse yourself in the unique atmosphere of this breathtaking region.",
				tags: [
					{
						key: "Distance",
						value: "200 Km",
					},
					{
						key: "Altitude",
						value: "12500 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
			},
			{
				day: "Day 5",
				title: "Sumur to Pangong",
				description:
					"Depart from your campsite and embark on a journey towards Pangong Lake. Revel in the scenic route leading to this exquisite lake nestled along the Indo-China border. Marvel at the breathtaking vistas of the Shyok River and majestic mountains en route to Pangong Lake. Witness the captivating spectacle of the lake's changing hues, transitioning from shades of blue to green and red as you approach Pangong Tso. Spend the night at a pre-arranged campsite near the lake, ensuring a tranquil and unforgettable experience amidst the beauty of Pangong Lake.",
				tags: [
					{
						key: "Distance",
						value: "230 Km (~140Km Offroading)",
					},
					{
						key: "Altitude",
						value: "14270 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
			},
			{
				day: "Day 6",
				title: "Pangong to Leh",
				description:
					"Depart from your campsite and head towards Leh, making a stop at the renowned Chang-la Pass, celebrated as one of the world's highest mountain passes. Along the way, discover iconic sites such as the Changla Baba Temple and the Druk Padma School. Don't miss the opportunity to explore the picturesque Thiksey Monastery and Shey Palace during your travels. After your sightseeing adventure, return your bike at the hotel and unwind with an overnight stay in Leh.",
				tags: [
					{
						key: "Distance",
						value: "200 Km",
					},
					{
						key: "Altitude",
						value: "17700 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner + Happy Hour",
					},
				],
			},
			{
				day: "Day 7",
				title: "Departure From Leh",
				description:
					"Pack your bags and check out of your hotel in Leh.",
				tags: [
					{
						key: "Meals",
						value: "Breakfast",
					},
				],
			},
		],
		keyInfoTabs: {
			
			inclusions: [
				"A-grade accommodations on double sharing basis.",
				"Meals: Dinner & Breakfast (Starting Day 1 Dinner to Day 7 Breakfast) + watebottles during the trip.",
				"RE Classic/Bullet/Himalayan/ Avenger/Xpulse provided with fuel as per your selected package. You will have to pay Rs 5000 (refundable) as a security deposit for Motorcycle on Day 1 which will be refunded at the end of the trip on successful return of the bike in good condition.",
				"Helmets for Rider and Pillion both, though we recommend to bring your own for comfortable fitting",
				"Bonfire at suitable location",
				"Experienced Road Captain on a vehicle",
				"24x7 Breakdown Assistance (Mechanic & support staff)",
				"Back-Up Vehicle specifically for luggage",
				"Inner Line Permits & Environmental Fees",
				"Oxygen Cylinders in case of emergency",
				"Basic Medical Kit",
			],
			exclusions: [
				"Cost of any spare parts consumed during the ride. Any cost arising due to accident of motorbike.",
				"Lunch, snacks, tolls, travel insurance, riding gears other than Helmet.",
				"Any food, travel and accomodation cost arising due to bad health or natural calamities like landslides, road blocks, bad weather etc.",
				"Any cost arising when itinerary changes due to force majeure",
				"Personal expenses such as table drinks, water bottles, alcohol, snacks, telephone calls, room service, laundry, tips to drivers, guides and hotel staff",
				"Applicable GST @ 5%",
				"Motorcycle security deposit of Rs 5000/- per bike (refundable).",
				"Any entry fees for monasteries and monuments or photo/video cameras.",
				"Towing charges of vehicles if left half way during the ride or during accidental cases",
				"Any payment demanded by a driver to ride your bike for some part of the trip if you are unable to ride the bike",
				"Anything not mentioned in tour inclusions",
			],
			paymentPolicy: [
				"An amount of INR 6000/- per person to be deposited for slot confirmation",
				"Balance amount to be deposited 45 days before the departure date.",
				"Failure to adhere to 'Payment Policy' can lead to cancellation of the booking without any prior notice.",
			],
			cancellationPolicy: [
				"100% of slot confirmation amount is refunded if trip cancelled within 7 days of booking.",
				"30 days or more before departure: 25% of total cost (Will Be Deducted)",
				"29 - 20 days before departure: 50% of total cost (Will Be Deducted)",
				"Less than 19 days before departure: 100% of total cost (Will Be Deducted)",
			],
			dates: [
				{
					fromDate: "12/05/2024",
					toDate: "18/05/2024",
					fromDateDay: "Wednesday",
					toDateDay: "Tuesday",
					offPercent: "No\u00A0Slots",
					saleWord:"Sold\u00A0Out",
				},
				{
					fromDate: "01/06/2024",
					toDate: "07/06/2024",
				},
				{
					fromDate: "21/06/2024",
					toDate: "27/06/2024",
				},
				{
					fromDate: "21/06/2024",
					toDate: "27/06/2024",
				},
				{
					fromDate: "11/07/2024",
					toDate: "17/07/2024",
				},
				{
					fromDate: "22/05/2024",
					toDate: "28/05/2024",
				},
				{
					fromDate: "22/05/2024",
					toDate: "28/05/2024",
				},
				{
					fromDate: "11/06/2024",
					toDate: "17/06/2024",
				},
				{
					fromDate: "01/07/2024",
					toDate: "07/07/2024",
				},
				{
					fromDate: "21/07/2024",
					toDate: "27/07/2024",
				},
			],
			reachUs: {
				city: "Manali",
				description:
					"Navigating the breathtaking landscapes of Leh, nestled in the heart of the Indian Himalayas, is a journey in itself. Here's your guide on the various ways to reach this enchanting destination: ",
				options: [
					{
						icon: (
							<FlightIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Flight (Most Convenient)",
						description:
							"Leh Kushok Bakula Rimpochee Airport connects the region with major cities like Delhi, Mumbai, and Srinagar. Flights offer a convenient and time-saving option, especially for those traveling from distant locations.",
					},
					{
						icon: (
							<TrainIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Train",
						description:
							"While Leh doesn't have a railway station, the nearest major railhead is Jammu Tawi Railway Station in Jammu. From there, you can opt for a road journey via bus or taxi, but this route involves a long and arduous journey.",
					},
					{
						icon: (
							<DirectionsCarIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Car",
						description:
							"Driving to Leh is an adventure in itself, offering breathtaking views along the way. The most popular route is via Srinagar-Leh Highway or Manali-Leh Highway, both of which offer stunning vistas but require careful planning due to challenging terrain and high-altitude passes.",
					},
					{
						icon: (
							<DirectionsBusIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Bus",
						description:
							"State-run and private buses operate between Leh and several cities in Jammu and Kashmir, including Srinagar and Jammu. Additionally, there's a scenic bus route from Manali to Leh, which is famous for its stunning views and challenging terrain. The Manali-Leh Highway offers a thrilling journey through high-altitude passes like Rohtang Pass and Baralacha La. While the bus journey from Manali to Leh is adventurous and budget-friendly, it's essential to prepare for long travel times and the possibility of altitude sickness.",
					},
				],
			},
			packingList: [
				{
					item: "Documents",
					details: [
						"4 passport size photographs.",
						"4 Copies of Photo ID proof. (Pan Card is not Valid ID proof)",
						"Valid driving License (Only for rider)",
						"Bike / Car papers",
					],
				},
				{
					item: "Riding Gear",
					details: [
						"Own Full Face Helmet(Recommended)/ Otherwise provided by us",
						"3 Layered Riding Jacket(Recommended)/ Elbow Pads(Available on Rent)",
						"Riding Pants(Recommended) / Knee Pads( Available on Rent)",
						"Riding Gloves(Available on Rent)",
					],
				},
				{
					item: "Shoes and Backpack",
					details: [
						"Riding Shoes(Recommended)/Trekking Shoes",
						"Rucksack Bag(Max 60L)",
					],
				},
				{
					item: "Warm Layers and Clothes",
					details: [
						"Padded Jackets",
						"Windcheater ",
						"Rain coat",
						"Fleece",
						"3-4 Jeans /3-4 Track pants",
						"5 to 6 T-shirt ",
						"2-3 pairs of thermal.",
						"Towels",
						"3-4 Pair of Cotton Socks, 3-4 Woolen socks",
						"Woolen Hand Gloves,Warm head cover, ear covers & Forehead covers ",
					],
				},
				{
					item: "Accessories",
					details: [
						"Sunglasses",
						"Water Bottle",
						"Multi-Plug",
						"Plastic Bags ",
						"Slippers",
					],
				},
				{
					item: "Toiletries",
					details: [
						"Toothbrush & Paste",
						"Toilet Soap/ Hand sanitizer.",
						"Tissue Papers ",
						"Face wash",
						"Moisturizer",
						"Talc powder",
						"Sunscreen",
						"Lip Balm",
						"Comb/ Brush",
					],
				},
				{
					item: "Medicines",
					details: [
						"Ensure you bring along your personal medical kit to address any potential ailments during your journey. While we do provide basic medications for emergencies, having your own supplies ensures you're prepared for any medical needs that may arise.",
					],
				},
			],
		},
		faqs: [
			{
				question: "What vehicles are provided for luggage carriers?",
				answer: "We offer Mahindra Bolero, Imperio, Scorpio Getaway, and iSuzu for luggage carriers.",
			},
			{
				question: "What riding gear is essential for the Ladakh trip?",
				answer: "Essential riding gear includes a riding jacket, riding pants, boots, gloves, and a full-face helmet. Refer the packing list for complete details",
			},
			{
				question: "Is it necessary to carry thermal wear?",
				answer: "Yes, Ladakh experiences extremely cold temperatures, and carrying thermal wear is recommended as temperatures can drop below freezing.",
			},
			{
				question: "What original documents must be carried?",
				answer: "Original documents required are Covid Vaccination Certificate, Driving Licence, Aadhar Card, and for self-bikers - RC, Insurance, and Pollution Certificate.",
			},
			{
				question: "Is there a risk of oxygen shortage during the trip?",
				answer: `Yes, riding at altitudes above 10,000 feet poses a risk of difficulty in breathing due to low atmospheric oxygen levels. The primary cause of AMS (Acute Mountain Sickness) is dehydration, often exacerbated by riders not consuming enough water. To mitigate AMS, follow these steps: 
                    1. Stay hydrated by drinking water mixed with Electral/ORS/Glucose.
                    2. Avoid pushing yourself too hard; take it slow to acclimate.
                    3. If feeling dizzy or uncomfortable, stop and take Diamox, which begins working within an hour.
                    4. If experiencing dizziness, seek oxygen assistance from the road captain.`,
			},
		],
	},
	manali2srinagar: {
		intro: {
			title: "Embark on an unforgettable journey with our ",
			mid: "Manali to Srinagar ",
			end: "motorcycle expedition.",
			description:
				"Embark on a visual and cultural odyssey tailored for true explorers, celebrating the diverse landscapes and rich experiences from Manali to Srinagar. This expedition unfolds a tapestry of nature's hues, transitioning from the vibrant greens of Manali to the rugged greys of Ladakh and culminating in the timeless allure of Srinagar. Whether you're enthralled by panoramic landscapes, intrigued by diverse cultures, or yearning for an adventure that transcends boundaries, this journey guarantees an unforgettable exploration of the Indian Himalayas. It's a perfect expedition for those seeking the extraordinary in every mile of their Manali to Srinagar adventure.",
			image: ManaliImage,
			payment_id: "ZXN5VY7PWBV5J",
			tags: [
				{
					key: "Days",
					value: "11 days, 9 Places",
					icon: "fa fa-calendar",
				},
				{
					key: "Accomodation",
					value: "8 Nights Hotel, 2 Nights Tent",
					icon: "fa fa-building",
				},
				{
					key: "Meals",
					value: "10 Dinner, 10 Breakfast",
					icon: "fa fa-coffee",
				},
				{
					key: "Group Size",
					value: "15-20 People",
					icon: "fa fa-users",
				},
				{
					key: "Transport",
					value: "Bikes, Pickup Truck",
					icon: "fa fa-bus",
				},
				{
					key: "Team",
					value: "Road Captain, Mechanic, Photographer, Support Vehicle Driver",
					icon: "fa fa-user-plus",
				},
			],
		},
		highlights: [
			{
				img: require("../media/dusty/ManaliSrinagar/bara.jpg"),
				title: "Baralacha Pass",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/21gata.jpg"),
				title: "21 Ghata loop",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/kardung.jpg"),
				title: "kardungla pass",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/turtuk.jpg"),
				title: "Turtuk Village",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/hunder.jpg"),
				title: "Hunder Sandunes camel ride",
				banner: "Own Expense",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/halloffame.jpg"),
				title: "Hall of Fame",
				banner: "Own Expense",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/pangong.jpg"),
				title: "Pangong",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/magnetic.jpg"),
				title: "Magnetic Hills",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/thiksey.jpg"),
				title: "Thiksey Monastery",
				banner: "Included",
			},
		],
		itinarary: [
			{
				day: "Day 1",
				title: "Arrival in Manali",
				description:
					"Arrive in the vibrant town of Manali, surrounded by towering peaks. Explore local attractions like Hadimba Devi Temple and Old Manali. Prepare for the upcoming road adventure",
				tags: [
					{
						key: "Altitude",
						value: "6260 Feet",
					},
					{
						key: "Meals",
						value: "Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 2",
				title: "Manali to Sarchu",
				description:
					"Embark on a captivating journey from Manali, cruising through the newly constructed Atal Tunnel at 10,040 feet. Conquer the formidable Baralacha La Pass at 16,040 feet, marking the transition from lush landscapes to the high-altitude desert of Sarchu at 14,070 feet. Marvel at Suraj Tal, a pristine lake surrounded by snow-capped peaks. The nomadic charm of Sarchu unfolds, offering a unique blend of barren lands and distant mountains.",
				tags: [
					{
						key: "Distance",
						value: "180 Km",
					},
					{
						key: "Altitude",
						value: "16040 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 3",
				title: "Sarchu to Leh:",
				description:
					"Journey from Sarchu's high-altitude desert at 14,070 feet through the famed 21 Gata Loops, revealing the Himalayan heart with each bend. Conquer the challenging Lachunga Pass, navigating winding roads amidst breathtaking vistas. Descend to the remote Pang village at 15,300 feet, embraced by towering peaks. Cross the surreal expanse of Morey Plains against the backdrop of majestic mountains. Ascend Tanglang La, standing at 17,582 feet, before finally reaching the ancient city of Leh at 11,562 feet. This odyssey showcases diverse terrains, from arid beauty to cultural richness, weaving an unforgettable tale amidst the Himalayan panorama.",
				tags: [
					{
						key: "Distance",
						value: "260 Km",
					},
					{
						key: "Altitude",
						value: "17582 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 4",
				title: "Leh Local Sightseeing",
				description:
					"Embark on a thrilling motorcycle expedition through the heart of Leh, discovering its captivating attractions. Honor the bravery of soldiers at the Hall of Fame, showcasing the rich military history of the region. Ascend to Shanti Stupa for panoramic views and peaceful contemplation before immersing yourself in the vibrant Leh Market. Step back in time at Leh Palace, a majestic architectural marvel, and conclude your adventure at Namgyal Tsemo, an ancient monastery steeped in spirituality and history.",
				tags: [
					{
						key: "Distance",
						value: "90 Km",
					},
					{
						key: "Altitude",
						value: "11562 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 5",
				title: "Leh to Nubra Valley",
				description:
					"Begin your day from your hotel towards the stunning Nubra Valley. Traverse the legendary Khardung-La Pass, renowned as the world's highest navigable road year-round. As you arrive in Nubra Valley, be captivated by its breathtaking landscapes, adorned with expansive white sand deserts. Take a moment to admire the grandeur of Diskit Monastery, if time permits. Delight in the unique experience of a camel ride on the double-humped Bactrian Camels amidst the valley's splendor, including the enchanting dunes of Hunder. Wrap up your day with an unforgettable overnight stay at a pre-arranged Hotel in Nubra Valley, promising magical moments under the starlit sky.",
				tags: [
					{
						key: "Distance",
						value: "130 Km",
					},
					{
						key: "Altitude",
						value: "18380 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 6",
				title: "Nubra to Sumur",
				description:
					"Set out on an unforgettable motorcycle adventure starting with a visit to Turtuk. Traverse the rugged terrain of Ladakh from Nubra to Sumur, winding through mountainous roads enveloped by towering peaks and vast desert vistas. Pause to explore the captivating sand dunes of Hunder, where you can enjoy camel rides against the stunning Himalayan backdrop. Discover the ancient mystique of Diskit and Sumur monasteries, immersing yourself in their rich history and spiritual significance. Relax in the serene ambiance of Sumur village, cradled amidst picturesque valleys and lush fields. End your day with a cozy bonfire in Sumur, adding warmth to your unforgettable journey.",
				tags: [
					{
						key: "Distance",
						value: "200 Km",
					},
					{
						key: "Altitude",
						value: "12500 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 7",
				title: "Sumur to Pangong",
				description:
					"Depart from your campsite and embark on a journey towards Pangong Lake. Revel in the scenic route leading to this exquisite lake nestled along the Indo-China border. Marvel at the breathtaking vistas of the Shyok River and majestic mountains en route to Pangong Lake. Witness the captivating spectacle of the lake's changing hues, transitioning from shades of blue to green and red as you approach Pangong Tso. Spend the night at a pre-arranged campsite near the lake, ensuring a tranquil and unforgettable experience amidst the beauty of Pangong Lake.",
				tags: [
					{
						key: "Distance",
						value: "230 Km(~140km Offroading)",
					},
					{
						key: "Altitude",
						value: "14270 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 8",
				title: "Pangong to Leh",
				description:
					"Depart from your campsite and head towards Leh, making a stop at the renowned Chang-la Pass, celebrated as one of the world's highest mountain passes. Along the way, discover iconic sites such as the Changla Baba Temple and the Druk Padma School. Don't miss the opportunity to explore the picturesque Thiksey Monastery and Shey Palace during your travels. After your sightseeing adventure, return your bike at the hotel and unwind with an overnight stay in Leh.",
				tags: [
					{
						key: "Distance",
						value: "225 Km",
					},
					{
						key: "Altitude",
						value: "17700 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 9",
				title: "Leh to Kargil",
				description:
					"Continue your journey by first visiting Gurudwara Pathar Sahib, a spiritual sanctuary in the rugged landscapes. Witness the perplexing Magnetic Hill, where gravity seems to play tricks. Continue to Sangam Point, the meeting place of the Indus and Zanskar Rivers, offering a breathtaking spectacle against the arid terrain. Explore Lamayuru Monastery, set against surreal moon-like landscapes. Conclude the expedition at Mulbek, where a life-size Buddha sculpture on Rock Mountain showcases ancient artistry. This Leh to Kargil road trip unveils a tapestry of spirituality, natural marvels, and cultural richness amid Ladakh's striking landscapes.",
				tags: [
					{
						key: "Distance",
						value: "215 Km",
					},
					{
						key: "Altitude",
						value: "13478 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 10",
				title: "Kargil to Srinagar",
				description:
					"Venture on a captivating journey from Kargil to Srinagar, immersing yourself in the scenic wonders reminiscent of your initial route. Traverse the majestic Zoji La Pass, unveiling rugged beauty with every twist and turn. Descend into the idyllic meadows of Sonamarg, where verdant landscapes and the serene Sindh River create a tranquil haven. Pay homage to the Kargil War heroes at the poignant Drass War Memorial. Wind through mesmerizing scenery, seamlessly connecting these two enchanting destinations, and embrace the diverse tapestry of nature on your return from Kargil to the captivating city of Srinagar. Upon reaching Srinagar, explore the city's gems like Dal Lake, Mughal gardens, and the vibrant markets, adding a touch of cultural richness to your journey.",
				tags: [
					{
						key: "Distance",
						value: "210 Km",
					},
					{
						key: "Altitude",
						value: "11575 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
			{
				day: "Day 11",
				title: "Depart from Srinagar",
				description:
					"Check out of the hotel and depart for your next adventures.",
				tags: [
					{
						key: "Altitude",
						value: "5200 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast",
					},
				],
				imageURl:require("../media/dusty/routes/manali2sriroute.jpg"),
			},
		],
		keyInfoTabs: {
			
			inclusions: [
				"A-grade accommodations on double sharing basis.",
				"Meals: Dinner & Breakfast (Starting Day 1 Dinner to Day 7 Breakfast) + watebottles during the trip.",
				"RE Classic/Bullet/Himalayan/ Avenger/Xpulse provided with fuel as per your selected package. You will have to pay Rs 5000 (refundable) as a security deposit for Motorcycle on Day 1 which will be refunded at the end of the trip on successful return of the bike in good condition.",
				"Helmets for Rider and Pillion both, though we recommend to bring your own for comfortable fitting",
				"Bonfire at suitable location",
				"Experienced Road Captain on a vehicle",
				"24x7 Breakdown Assistance (Mechanic & support staff)",
				"Back-Up Vehicle specifically for luggage",
				"Inner Line Permits & Environmental Fees",
				"Oxygen Cylinders in case of emergency",
				"Basic Medical Kit",
			],
			exclusions: [
				"Cost of any spare parts consumed during the ride. Any cost arising due to accident of motorbike.",
				"Lunch, snacks, tolls, travel insurance, riding gears other than Helmet.",
				"Any food, travel and accomodation cost arising due to bad health or natural calamities like landslides, road blocks, bad weather etc.",
				"Any cost arising when itinerary changes due to force majeure",
				"Personal expenses such as table drinks, water bottles, alcohol, snacks, telephone calls, room service, laundry, tips to drivers, guides and hotel staff",
				"Motorcycle security deposit of Rs 5000/- per bike (refundable).",
				"Any entry fees for monasteries and monuments or photo/video cameras.",
				"Towing charges of vehicles if left half way during the ride or during accidental cases",
				"Any payment demanded by a driver to ride your bike for some part of the trip if you are unable to ride the bike",
				"Anything not mentioned in tour inclusions",
			],
			paymentPolicy: [
				"An amount of USD 200/- per person to be deposited for slot confirmation",
				"Balance amount to be deposited 45 days before the departure date.",
				"Failure to adhere to 'Payment Policy' can lead to cancellation of the booking without any prior notice.",
			],
			cancellationPolicy: [
				"100% of slot confirmation amount is refunded if trip cancelled within 7 days of booking.",
				"30 days or more before departure: 25% of total cost (Will Be Deducted)",
				"29 - 20 days before departure: 50% of total cost (Will Be Deducted)",
				"Less than 19 days before departure: 100% of total cost (Will Be Deducted)",
			],
			dates: [
				
				{
					fromDate: "10\u00A0May\u00A02024",
					toDate: "20\u00A0May\u00A02024",
					fromDateDay: "Friday",
					toDateDay: "Monday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A02,099/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,749/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A02,099/-",
						},]
				},
				{
					fromDate: "30\u00A0May\u00A02024",
					toDate: "09\u00A0Jun\u00A02024",
					fromDateDay: "Thursday",
					toDateDay: "Sunday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A02,099/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,749/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A02,099/-",
						},]
				},
				{
					fromDate: "19\u00A0Jun\u00A02024",
					toDate: "29\u00A0Jun\u00A02024",
					fromDateDay: "Wednesday",
					toDateDay: "Saturday",
					offPercent: "$\u00A0200\u00A0off",
					saleWord:"Hurry!",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,899/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,749/-",
							price2: "$\u00A01,549/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,899/-",
						},]
				},
				{
					fromDate: "09\u00A0Jul\u00A02024",
					toDate: "19\u00A0Jul\u00A02024",
					fromDateDay: "Wednesday",
					toDateDay: "Saturday",
					offPercent: "No\u00A0Slots",
					saleWord:"Sold\u00A0Out",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,699/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,749/-",
							price2: "$\u00A01,399/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,699/-",
						},]
				},
			],
			reachUs: {
				city: "Manali",
				description:
					"Navigating the breathtaking landscapes of Leh, nestled in the heart of the Indian Himalayas, is a journey in itself. Here's your guide on the various ways to reach this enchanting destination: ",
				options: [
					{
						icon: (
							<FlightIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Flight",
						description:
							"Riders seeking a quicker option can take a flight to Sheikh ul-Alam International Airport in Srinagar. Multiple airlines operate regular flights, providing a convenient and time-efficient means of reaching the starting point of the Srinagar to Manali trip.",
					},
					{
						icon: (
							<TrainIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Train",
						description:
							"The nearest major railway station is Jammu Tawi. From there, riders can take a taxi, bus, or hire a car to reach Srinagar. The train journey itself provides scenic views, and the road trip from Jammu to Srinagar offers glimpses of the stunning Himalayan landscape.",
					},
					{
						icon: (
							<DirectionsCarIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Car",
						description:
							"Driving to Srinagar offers flexibility and the chance to enjoy the picturesque journey. Navigate through well-maintained highways, such as the NH1, relishing the changing landscapes. It allows for a personalized itinerary, with potential stops at scenic spots and exploration along the way.",
					},
					{
						icon: (
							<DirectionsBusIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Bus",
						description:
							"Srinagar is well-connected by a network of state and private buses. Riders can opt for a comfortable bus journey, experiencing the beauty of the journey through the winding roads and valleys. Various classes of buses are available to suit different preferences.",
					},
				],
			},
			packingList: [
				{
					item: "Documents",
					details: [
						"4 passport size photographs.",
						"4 Copies of Photo ID proof. (Pan Card is not Valid ID proof)",
						"Valid driving License (Only for rider)",
						"Bike / Car papers",
					],
				},
				{
					item: "Riding Gear",
					details: [
						"Own Full Face Helmet(Recommended)/ Otherwise provided by us",
						"3 Layered Riding Jacket(Recommended)/ Elbow Pads(Available on Rent)",
						"Riding Pants(Recommended) / Knee Pads( Available on Rent)",
						"Riding Gloves(Available on Rent)",
					],
				},
				{
					item: "Shoes and Backpack",
					details: [
						"Riding Shoes(Recommended)/Trekking Shoes",
						"Rucksack Bag(Max 60L)",
					],
				},
				{
					item: "Warm Layers and Clothes",
					details: [
						"Padded Jackets",
						"Windcheater ",
						"Rain coat",
						"Fleece",
						"3-4 Jeans /3-4 Track pants",
						"5 to 6 T-shirt ",
						"2-3 pairs of thermal.",
						"Towels",
						"3-4 Pair of Cotton Socks, 3-4 Woolen socks",
						"Woolen Hand Gloves,Warm head cover, ear covers & Forehead covers ",
					],
				},
				{
					item: "Accessories",
					details: [
						"Sunglasses",
						"Water Bottle",
						"Multi-Plug",
						"Plastic Bags ",
						"Slippers",
					],
				},
				{
					item: "Toiletries",
					details: [
						"Toothbrush & Paste",
						"Toilet Soap/ Hand sanitizer.",
						"Tissue Papers ",
						"Face wash",
						"Moisturizer",
						"Talc powder",
						"Sunscreen",
						"Lip Balm",
						"Comb/ Brush",
					],
				},
				{
					item: "Medicines",
					details: [
						"Ensure you bring along your personal medical kit to address any potential ailments during your journey. While we do provide basic medications for emergencies, having your own supplies ensures you're prepared for any medical needs that may arise.",
					],
				},
			],
		},
		faqs: [
			{
				question: "What vehicles are provided for luggage carriers?",
				answer: "We offer Mahindra Bolero, Imperio, Scorpio Getaway, and iSuzu for luggage carriers.",
			},
			{
				question: "What riding gear is essential for the Ladakh trip?",
				answer: "Essential riding gear includes a riding jacket, riding pants, boots, gloves, and a full-face helmet. Refer the packing list for complete details",
			},
			{
				question: "Is it necessary to carry thermal wear?",
				answer: "Yes, Ladakh experiences extremely cold temperatures, and carrying thermal wear is recommended as temperatures can drop below freezing.",
			},
			{
				question: "What original documents must be carried?",
				answer: "Original documents required are Covid Vaccination Certificate, Driving Licence, Aadhar Card, and for self-bikers - RC, Insurance, and Pollution Certificate.",
			},
			{
				question: "Is there a risk of oxygen shortage during the trip?",
				answer: `Yes, riding at altitudes above 10,000 feet poses a risk of difficulty in breathing due to low atmospheric oxygen levels. The primary cause of AMS (Acute Mountain Sickness) is dehydration, often exacerbated by riders not consuming enough water. To mitigate AMS, follow these steps: 
                    1. Stay hydrated by drinking water mixed with Electral/ORS/Glucose.
                    2. Avoid pushing yourself too hard; take it slow to acclimate.
                    3. If feeling dizzy or uncomfortable, stop and take Diamox, which begins working within an hour.
                    4. If experiencing dizziness, seek oxygen assistance from the road captain.`,
			},
			{
				question: "Are ATMs available in Leh?",
				answer: "Yes, Leh has ATMs, but network issues may cause some to be non-functional. It is advisable to carry sufficient cash throughout the trip.",
			},
			{
				question: "Which mobile network is accessible in Ladakh?",
				answer: "Postpaid Jio, Airtel, and BSNL networks work well in Ladakh.",
			},
			{
				question: "I want to bring my own bike, can I come?",
				answer: "Yes you can bring your own bike. Select the appropriate package. Please note that due to the laws of Leh Union, you are only allowed to bring a bike that you or your immediate family members own. You will have to provide proof of ownership and relationship to the family member."
			}
		],
	},
	srinagar2manali: {
		intro: {
			title: "Embark on an unforgettable journey with our ",
			mid: "Srinagar to Manali ",
			end: "motorcycle expedition.",
			description:
				"This expedition is a visual and cultural odyssey, perfect for explorers seeking diverse landscapes and rich experiences. Designed for true explorers, this expedition is a celebration of the varied hues of nature, from the vibrant greens of Kashmir to the rugged greys of Ladakh and the timeless allure of Manali. Whether you're captivated by panoramic landscapes, fascinated by diverse cultures, or simply seeking an adventure that transcends boundaries, this journey promises an unforgettable exploration of the Indian Himalayas.",
			image: SrinagarImage,
			payment_id: "VY9RJCVP46RZL",
			tags: [
				{
					key: "Days",
					value: "11 days, 9 Places",
					icon: "fa fa-calendar",
				},
				{
					key: "Accomodation",
					value: "8 Nights Hotel, 2 Nights Tent",
					icon: "fa fa-building",
				},
				{
					key: "Meals",
					value: "10 Dinner, 10 Breakfast",
					icon: "fa fa-coffee",
				},
				{
					key: "Group Size",
					value: "15-20 People",
					icon: "fa fa-users",
				},
				{
					key: "Transport",
					value: "Bikes, Pickup Truck",
					icon: "fa fa-bus",
				},
				{
					key: "Team",
					value: "Road Captain, Mechanic, Photographer, Support Vehicle Driver",
					icon: "fa fa-user-plus",
				},
			],
		},
		highlights: [
			{
				img: require("../media/dusty/ManaliSrinagar/bara.jpg"),
				title: "Baralacha Pass",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/21gata.jpg"),
				title: "21 Ghata loop",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/kardung.jpg"),
				title: "kardungla pass",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/turtuk.jpg"),
				title: "Turtuk Village",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/hunder.jpg"),
				title: "Hunder Sandunes camel ride",
				banner: "Own Expense",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/halloffame.jpg"),
				title: "Hall of Fame",
				banner: "Own Expense",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/hunder.jpg"),
				title: "Pangong",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/magnetic.jpg"),
				title: "Magnetic Hills",
				banner: "Included",
			},
			{
				img: require("../media/dusty/ManaliSrinagar/thiksey.jpg"),
				title: "Thiksey Monastery",
				banner: "Included",
			},
		],
		itinarary: [
			{
				day: "Day 1",
				title: "Arrival in Srinagar",
				description:
					"Arrive in the enchanting city of Srinagar, where the shimmering Dal Lake and Mughal gardens welcome you. Immerse yourself in the local culture, perhaps taking a shikara ride or exploring the vibrant markets. Take this time to explore the culinary delicacies natural beauty of Kashmir.",
				tags: [
					{
						key: "Altitude",
						value: "5200 Feet",
					},
					{
						key: "Meals",
						value: "Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
				
			},
			{
				day: "Day 2",
				title: "Srinagar to Kargil",
				description:
					"Embark on an adventurous ride from Srinagar to Kargil, crossing the formidable Zoji La Pass. As you journey through the breathtaking landscapes, make a memorable stop at Sonamarg, the 'Meadow of Gold.' Surrounded by snow-capped peaks, Sonamarg offers a visual feast with its lush meadows, pristine Sindh River, and the captivating Thajiwas Glacier. Along the way, pay respects at the Drass War Memorial, commemorating the heroes of the Kargil War.",
				tags: [
					{
						key: "Distance",
						value: "210 Km",
					},
					{
						key: "Altitude",
						value: "11,575 Feet",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 3",
				title: "Kargil to Leh",
				description:
					"In the next leg of the motorbike journey, two high-altitude mountain passes await after leaving Kargil. Mulbek introduces a life-size Buddha sculpture on Rock Mountain, followed by Lamayuru, hosting one of Ladakh's oldest monasteries amidst a mesmerizing moon landscape. As Leh approaches, marvel at the confluence of the Indus and Zanskar Rivers at Sangam Point, where muddy Zanskar meets the clear Indus, creating a unique spectacle in the arid landscapes. Capture the optical illusion of Magnetic Hill, where natural forces play with gravity, providing a captivating visual experience. Experience divinity at Gurudwara Pathar Sahib and witness the mind-boggling Magnetic Hills phenomenon.",
				tags: [
					{
						key: "Distance",
						value: " Approximately 215 Km",
					},
					{
						key: "Altitude",
						value: "13478 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 4",
				title: "Leh Local Sightseeing",
				description:
					"Embark on a thrilling motorcycle expedition through the heart of Leh, discovering its captivating attractions. Honor the bravery of soldiers at the Hall of Fame, showcasing the rich military history of the region. Ascend to Shanti Stupa for panoramic views and peaceful contemplation before immersing yourself in the vibrant Leh Market. Step back in time at Leh Palace, a majestic architectural marvel, and conclude your adventure at Namgyal Tsemo, an ancient monastery steeped in spirituality and history.",
				tags: [
					{
						key: "Distance",
						value: "90 Km",
					},
					{
						key: "Altitude",
						value: "11562 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 5",
				title: "Leh to Nubra Valley",
				description:
					"Begin your day from your hotel towards the stunning Nubra Valley. Traverse the legendary Khardung-La Pass, renowned as the world's highest navigable road year-round. As you arrive in Nubra Valley, be captivated by its breathtaking landscapes, adorned with expansive white sand deserts. Take a moment to admire the grandeur of Diskit Monastery, if time permits. Delight in the unique experience of a camel ride on the double-humped Bactrian Camels amidst the valley's splendor, including the enchanting dunes of Hunder. Wrap up your day with an unforgettable overnight stay at a pre-arranged Hotel in Nubra Valley, promising magical moments under the starlit sky.",
				tags: [
					{
						key: "Distance",
						value: "130 Km",
					},
					{
						key: "Altitude",
						value: "18380 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 6",
				title: "Nubra to Sumur",
				description:
					"Set out on an unforgettable motorcycle adventure starting with a visit to Turtuk. Traverse the rugged terrain of Ladakh from Nubra to Sumur, winding through mountainous roads enveloped by towering peaks and vast desert vistas. Pause to explore the captivating sand dunes of Hunder, where you can enjoy camel rides against the stunning Himalayan backdrop. Discover the ancient mystique of Diskit and Sumur monasteries, immersing yourself in their rich history and spiritual significance. Relax in the serene ambiance of Sumur village, cradled amidst picturesque valleys and lush fields. End your day with a cozy bonfire in Sumur, adding warmth to your unforgettable journey.",
				tags: [
					{
						key: "Distance",
						value: "200 Km",
					},
					{
						key: "Altitude",
						value: "12500 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 7",
				title: "Sumur to Pangong",
				description:
					"Depart from your campsite and embark on a journey towards Pangong Lake. Revel in the scenic route leading to this exquisite lake nestled along the Indo-China border. Marvel at the breathtaking vistas of the Shyok River and majestic mountains en route to Pangong Lake. Witness the captivating spectacle of the lake's changing hues, transitioning from shades of blue to green and red as you approach Pangong Tso. Spend the night at a pre-arranged campsite near the lake, ensuring a tranquil and unforgettable experience amidst the beauty of Pangong Lake.",
				tags: [
					{
						key: "Distance",
						value: "230 Km(~140km Offroading)",
					},
					{
						key: "Altitude",
						value: "14270 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 8",
				title: "Pangong to Leh",
				description:
					"Depart from your campsite and head towards Leh, making a stop at the renowned Chang-la Pass, celebrated as one of the world's highest mountain passes. Along the way, discover iconic sites such as the Changla Baba Temple and the Druk Padma School. Don't miss the opportunity to explore the picturesque Thiksey Monastery and Shey Palace during your travels. After your sightseeing adventure, return your bike at the hotel and unwind with an overnight stay in Leh.",
				tags: [
					{
						key: "Distance",
						value: "225 Km",
					},
					{
						key: "Altitude",
						value: "17700 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 9",
				title: "Leh to Sarchu",
				description:
					"Embark on a high-altitude journey from Leh, conquering the formidable Tanglang La Pass. Descend to the vast Morey Plains, surrounded by towering peaks. Reach Pang, a remote village at 15,300 feet. Brace for the challenging Lachung La Pass, winding through its roads. Conquer the famed 21 Gata Loops, each bend revealing the Himalayan heart. Finally, arrive at Sarchu, approximately 14,070 feet. The nomadic charm of this high-altitude desert region marks the end of a day filled with diverse terrains, thrilling passes, and unforgettable landscapes, creating a memorable chapter in your Himalayan adventure.",
				tags: [
					{
						key: "Distance",
						value: "260 Km",
					},
					{
						key: "Altitude",
						value: "17582 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
			{
				day: "Day 10",
				title: "Sarchu to Manali",
				description:
					"Conquer Baralacha La Pass at 16,040 feet, witnessing the landscape shift from arid deserts to green valleys. Descend past Suraj Tal Lake, surrounded by snow-capped peaks.Explore Keylong's culture and architecture in Lahaul Valley. Venture through scenic Suru Valley, reaching Manali through the Atal Tunnel at 10,040 feet.Descend through picturesque landscapes, passing villages, and lush valleys. Reach Manali at 6,260 feet, exploring its vibrant attractions – Hadimba Devi Temple, Old Manali, and Mall Road. Conclude a day of diverse terrains, high passes, and engineering marvels.",
				tags: [
					{
						key: "Distance",
						value: "180 Km",
					},
					{
						key: "Altitude",
						value: "16040 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner + Happy Hour",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			}, 
			{
				day: "Day 11",
				title: "Depart from Manali",
				description:
					"Check out of the hotel and depart for your next adventures.",
				tags: [
					{
						key: "Altitude",
						value: "6260 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast",
					},
				],
				imageURl:require("../media/dusty/routes/sri2manaliroute.jpg"),
			},
		],
		keyInfoTabs: {
			
			inclusions: [
				"A-grade accommodations on double sharing basis.",
				"Meals: Dinner & Breakfast (Starting Day 1 Dinner to Day 7 Breakfast) + watebottles during the trip.",
				"RE Classic/Bullet/Himalayan/ Avenger/Xpulse provided with fuel as per your selected package. You will have to pay Rs 5000 (refundable) as a security deposit for Motorcycle on Day 1 which will be refunded at the end of the trip on successful return of the bike in good condition.",
				"Helmets for Rider and Pillion both, though we recommend to bring your own for comfortable fitting",
				"Bonfire at suitable location",
				"Experienced Road Captain on a vehicle",
				"24x7 Breakdown Assistance (Mechanic & support staff)",
				"Back-Up Vehicle specifically for luggage",
				"Inner Line Permits & Environmental Fees",
				"Oxygen Cylinders in case of emergency",
				"Basic Medical Kit",
			],
			exclusions: [
				"Cost of any spare parts consumed during the ride. Any cost arising due to accident of motorbike.",
				"Lunch, snacks, tolls, travel insurance, riding gears other than Helmet.",
				"Any food, travel and accomodation cost arising due to bad health or natural calamities like landslides, road blocks, bad weather etc.",
				"Any cost arising when itinerary changes due to force majeure",
				"Personal expenses such as table drinks, water bottles, alcohol, snacks, telephone calls, room service, laundry, tips to drivers, guides and hotel staff",
				"Motorcycle security deposit of Rs 5000/- per bike (refundable).",
				"Any entry fees for monasteries and monuments or photo/video cameras.",
				"Towing charges of vehicles if left half way during the ride or during accidental cases",
				"Any payment demanded by a driver to ride your bike for some part of the trip if you are unable to ride the bike",
				"Anything not mentioned in tour inclusions",
			],
			paymentPolicy: [
				"An amount of USD 200/- per person to be deposited for slot confirmation",
				"Balance amount to be deposited 45 days before the departure date.",
				"Failure to adhere to 'Payment Policy' can lead to cancellation of the booking without any prior notice.",
			],
			cancellationPolicy: [
				"100% of slot confirmation amount is refunded if trip cancelled within 7 days of booking.",
				"30 days or more before departure: 25% of total cost (Will Be Deducted)",
				"29 - 20 days before departure: 50% of total cost (Will Be Deducted)",
				"Less than 19 days before departure: 100% of total cost (Will Be Deducted)",
			],
			dates: [
				
				{
					fromDate: "20\u00A0May\u00A02024",
					toDate: "30\u00A0May\u00A02024",
					fromDateDay: "Monday",
					toDateDay: "Thursday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A02,099/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,749/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A02,099/-",
						},]
				},
				{
					fromDate: "09\u00A0Jun\u00A02024",
					toDate: "19\u00A0Jun\u00A02024",
					fromDateDay: "Sunday",
					toDateDay: "Wednesday",
					offPercent: "$\u00A0200\u00A0off",
					saleWord:"Hurry!",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,899/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,749/-",
							price2: "$\u00A01,549/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,899/-",
						},]
				},
				{
					fromDate: "29\u00A0Jun\u00A02024",
					toDate: "09\u00A0Jul\u00A02024",
					fromDateDay: "Monday",
					toDateDay: "Thursday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A02,099/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,749/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A02,099/-",
						},]
				},
				{
					fromDate: "19\u00A0Jul\u00A02024",
					toDate: "29\u00A0Jul\u00A02024",
					fromDateDay: "Friday",
					toDateDay: "Monday",
					offPercent: "No\u00A0Slots",
					saleWord:"Sold\u00A0Out",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,699/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,749/-",
							price2: "$\u00A01,399/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A02,099/-",
							price2: "$\u00A01,699/-",
						},]
				},
			],
			reachUs: {
				city: "Srinagar",
				description:
					"Navigating the breathtaking landscapes of Leh, nestled in the heart of the Indian Himalayas, is a journey in itself. Here's your guide on the various ways to reach this enchanting destination: ",
				options: [
					{
						icon: (
							<FlightIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Flight",
						description:
							"Riders seeking a quicker option can take a flight to Sheikh ul-Alam International Airport in Srinagar. Multiple airlines operate regular flights, providing a convenient and time-efficient means of reaching the starting point of the Srinagar to Manali trip.",
					},
					{
						icon: (
							<TrainIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Train",
						description:
							"The nearest major railway station is Jammu Tawi. From there, riders can take a taxi, bus, or hire a car to reach Srinagar. The train journey itself provides scenic views, and the road trip from Jammu to Srinagar offers glimpses of the stunning Himalayan landscape.",
					},
					{
						icon: (
							<DirectionsCarIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Car",
						description:
							"Driving to Srinagar offers flexibility and the chance to enjoy the picturesque journey. Navigate through well-maintained highways, such as the NH1, relishing the changing landscapes. It allows for a personalized itinerary, with potential stops at scenic spots and exploration along the way.",
					},
					{
						icon: (
							<DirectionsBusIcon
								style={{
									fontSize: "3rem",
									marginBottom: "1rem",
								}}
							/>
						),
						vehicle: "By Bus",
						description:
							"Srinagar is well-connected by a network of state and private buses. Riders can opt for a comfortable bus journey, experiencing the beauty of the journey through the winding roads and valleys. Various classes of buses are available to suit different preferences.",
					},
				],
			},
			packingList: [
				{
					item: "Documents",
					details: [
						"4 passport size photographs.",
						"4 Copies of Photo ID proof. (Pan Card is not Valid ID proof)",
						"Valid driving License (Only for rider)",
						"Bike / Car papers",
					],
				},
				{
					item: "Riding Gear",
					details: [
						"Own Full Face Helmet(Recommended)/ Otherwise provided by us",
						"3 Layered Riding Jacket(Recommended)/ Elbow Pads(Available on Rent)",
						"Riding Pants(Recommended) / Knee Pads( Available on Rent)",
						"Riding Gloves(Available on Rent)",
					],
				},
				{
					item: "Shoes and Backpack",
					details: [
						"Riding Shoes(Recommended)/Trekking Shoes",
						"Rucksack Bag(Max 60L)",
					],
				},
				{
					item: "Warm Layers and Clothes",
					details: [
						"Padded Jackets",
						"Windcheater ",
						"Rain coat",
						"Fleece",
						"3-4 Jeans /3-4 Track pants",
						"5 to 6 T-shirt ",
						"2-3 pairs of thermal.",
						"Towels",
						"3-4 Pair of Cotton Socks, 3-4 Woolen socks",
						"Woolen Hand Gloves,Warm head cover, ear covers & Forehead covers ",
					],
				},
				{
					item: "Accessories",
					details: [
						"Sunglasses",
						"Water Bottle",
						"Multi-Plug",
						"Plastic Bags ",
						"Slippers",
					],
				},
				{
					item: "Toiletries",
					details: [
						"Toothbrush & Paste",
						"Toilet Soap/ Hand sanitizer.",
						"Tissue Papers ",
						"Face wash",
						"Moisturizer",
						"Talc powder",
						"Sunscreen",
						"Lip Balm",
						"Comb/ Brush",
					],
				},
				{
					item: "Medicines",
					details: [
						"Ensure you bring along your personal medical kit to address any potential ailments during your journey. While we do provide basic medications for emergencies, having your own supplies ensures you're prepared for any medical needs that may arise.",
					],
				},
			],
		},
		faqs: [
			{
				question: "What vehicles are provided for luggage carriers?",
				answer: "We offer Mahindra Bolero, Imperio, Scorpio Getaway, and iSuzu for luggage carriers.",
			},
			{
				question: "What riding gear is essential for the Ladakh trip?",
				answer: "Essential riding gear includes a riding jacket, riding pants, boots, gloves, and a full-face helmet. Refer the packing list for complete details",
			},
			{
				question: "Is it necessary to carry thermal wear?",
				answer: "Yes, Ladakh experiences extremely cold temperatures, and carrying thermal wear is recommended as temperatures can drop below freezing.",
			},
			{
				question: "What original documents must be carried?",
				answer: "Original documents required are Covid Vaccination Certificate, Driving Licence, Aadhar Card, and for self-bikers - RC, Insurance, and Pollution Certificate.",
			},
			{
				question: "Is there a risk of oxygen shortage during the trip?",
				answer: `Yes, riding at altitudes above 10,000 feet poses a risk of difficulty in breathing due to low atmospheric oxygen levels. The primary cause of AMS (Acute Mountain Sickness) is dehydration, often exacerbated by riders not consuming enough water. To mitigate AMS, follow these steps: 
                    1. Stay hydrated by drinking water mixed with Electral/ORS/Glucose.
                    2. Avoid pushing yourself too hard; take it slow to acclimate.
                    3. If feeling dizzy or uncomfortable, stop and take Diamox, which begins working within an hour.
                    4. If experiencing dizziness, seek oxygen assistance from the road captain.`,
			},
			{
				question: "Are ATMs available in Leh?",
				answer: "Yes, Leh has ATMs, but network issues may cause some to be non-functional. It is advisable to carry sufficient cash throughout the trip.",
			},
			{
				question: "Which mobile network is accessible in Ladakh?",
				answer: "Postpaid Jio, Airtel, and BSNL networks work well in Ladakh.",
			},
			{
				question: "I want to bring my own bike, can I come?",
				answer: "Yes you can bring your own bike. Select the appropriate package. Please note that due to the laws of Leh Union, you are only allowed to bring a bike that you or your immediate family members own. You will have to provide proof of ownership and relationship to the family member."
			}
		],
	},
	spiti: {
		intro: {
			title: "Embark on an unforgettable journey with our ",
			mid: "Spiti Valley ",
			end: "motorcycle expedition.",
			description:
				"Embark on a visual and cultural odyssey tailored for true explorers, celebrating the diverse landscapes and rich experiences from Manali to Srinagar. This expedition unfolds a tapestry of nature's hues, transitioning from the vibrant greens of Manali to the rugged greys of Ladakh and culminating in the timeless allure of Srinagar. Whether you're enthralled by panoramic landscapes, intrigued by diverse cultures, or yearning for an adventure that transcends boundaries, this journey guarantees an unforgettable exploration of the Indian Himalayas. It's a perfect expedition for those seeking the extraordinary in every mile of their Manali to Srinagar adventure.",
			image: SpitiImage,
			payment_id: "MUSUTD53XGECY",
			tags: [
				{
					key: "Days",
					value: "9 days, 6 Places",
					icon: "fa fa-calendar",
				},
				{
					key: "Accomodation",
					value: "7 Nights Hotel, 1 Night in Camp",
					icon: "fa fa-building",
				},
				{
					key: "Meals",
					value: "8 Dinner, 8 Breakfast",
					icon: "fa fa-coffee",
				},
				{
					key: "Group Size",
					value: "15-20 People",
					icon: "fa fa-users",
				},
				{
					key: "Transport",
					value: "Bikes, Pickup Truck",
					icon: "fa fa-bus",
				},
				{
					key: "Team",
					value: "Road Captain, Mechanic, Photographer, Support Vehicle Driver",
					icon: "fa fa-user-plus",
				},
			],
		},
		highlights: [
			{
				img: require("../media/dusty/Spiti/tirthan.jpg"),
				title: "Tirthan Valley",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/jalori.jpg"),
				title: "Jalori Pass",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/chitkul.jpg"),
				title: "Chitkul",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/nako.jpg"),
				title: "Nako Monastery",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/dhankar.jpg"),
				title: "Dhankar Monastery",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/kaza.jpg"),
				title: "Kaza City",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/keymonastery.jpg"),
				title: "Key Monastery",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/chicham.jpg"),
				title: "Chicham Bridge",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/key.jpg"),
				title: "Khibbar",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/kunjum.jpg"),
				title: "Kunjum Pass",
				banner: "Included",
			},
			{
				img: require("../media/dusty/Spiti/chandratal.jpg"),
				title: "Chandratal",
				banner: "Included",
			},
		],
		itinarary: [
			{
				day: "Day 1",
				title: "Manali",
				description:
					"Arrive in the picturesque town of Manali, nestled amidst towering peaks. Take in the local charm and explore attractions like Hadimba Devi Temple and Old Manali. Get ready for the thrilling journey ahead through the stunning landscapes of Spiti Valley.",
				tags: [
					{
						key: "Altitude",
						value: "6260 Feet",
					},
					{
						key: "Meals",
						value: "Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 2",
				title: "Manali to Jibhi",
				description:
					"Embark on an exciting road trip from Manali to Jibhi, enjoying the scenic drive through lush forests and charming villages. Experience the tranquility of Jibhi and unwind in the serene atmosphere.",
				tags: [
					{
						key: "Distance",
						value: "100 Km",
					},
					{
						key: "Altitude",
						value: "6700 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 3",
				title: "Jibhi to Chitkul via Jalori Pass",
				description:
					"Travel through the enchanting Jalori Pass to reach Chitkul, the last inhabited village near the Indo-China border. Marvel at the beauty of the landscapes as you pass through this high-altitude region.",
				tags: [
					{
						key: "Distance",
						value: "150 Km",
					},
					{
						key: "Altitude",
						value: "~11,000 feet (Jalori Pass), ~11,319 feet (Chitkul)",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 4",
				title: "Chitkul to Nako",
				description:
					"Continue your journey to Nako, a charming village with a serene lake surrounded by mountains. Explore the ancient monasteries and immerse yourself in the peaceful ambiance of the Nako Lake. Admire the reflections of the surrounding peaks in the clear waters of the lake, adding to the enchantment of this high-altitude destination. Take some time to stroll around the lake and absorb the serene beauty before continuing your exploration of Spiti Valley.",
				tags: [
					{
						key: "Distance",
						value: "130 Km",
					},
					{
						key: "Altitude",
						value: "12014 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 5",
				title: "Nako to Kaza",
				description:
					"Head towards Kaza, the largest town in Spiti Valley. Encounter the unique landscapes of Spiti and immerse yourself in the rich culture of the region. Enroute, make a stop at Dhankar Monastery, perched dramatically on a high cliff. Explore this ancient monastery, which offers breathtaking views of the Spiti River and the surrounding mountains. Continue your journey to Kaza, absorbing the spiritual and natural wonders along the way.",
				tags: [
					{
						key: "Distance",
						value: "115 Km",
					},
					{
						key: "Altitude",
						value: "12467 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 6",
				title: "Local Sightseeing in Kaza",
				description:
					"Explore Kaza's spiritual gems at Key Monastery and Dhankar Monastery. Dive into local culture at traditional markets, discovering handmade crafts and souvenirs. Enjoy the cozy ambiance of local cafes, savoring butter tea and Spitian delicacies while soaking in breathtaking mountain views. Engage with the vibrant community, sharing stories of this unique high-altitude desert. The day culminates with a delightful dinner, a fusion of cultural and culinary delights. The rest of the itinerary remains unchanged, promising a continued journey through the enchanting landscapes of Spiti Valley.",
				tags: [
					{
						key: "Altitude",
						value: "12467 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 7",
				title: "Kaza to Chandratal",
				description:
					"Embark on a captivating journey via Kunzum Pass to Chandratal, the high-altitude lake of the moon. Traverse the awe-inspiring Chicham Bridge, an engineering marvel suspended over a deep gorge, offering exhilarating views of the rugged terrain. Explore Kibber Village, one of the highest inhabited villages globally, with its ancient monastery and traditional Spitian architecture. Continue the journey through the scenic landscapes, reaching the enchanting Chandratal. Spend the night at a pre-arranged campsite, surrounded by the pristine beauty of the moon-shaped lake.",
				tags: [
					{
						key: "Distance",
						value: "100 Km",
					},
					{
						key: "Altitude",
						value: "~14,000 feet (Kunzum Pass), ~14,100 feet (Chandratal)",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 8",
				title: "Chandratal to Manali via Atal Tunnel",
				description:
					"Bid farewell to the enchanting Chandratal and make your way back to Manali via the Atal Tunnel. Marvel at the engineering marvel of the tunnel and enjoy the scenic drive back.",
				tags: [
					{
						key: "Distance",
						value: "150 Km",
					},
					{
						key: "Altitude",
						value: "~10,040 feet (Atal Tunnel)",
					},
					{
						key: "Meals",
						value: "Breakfast and Dinner",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
			{
				day: "Day 9",
				title: "Manali",
				description:
					"Conclude your Spiti Valley adventure with a relaxing day in Manali. Explore any remaining attractions, shop for souvenirs, and reflect on the unforgettable experiences of your Spiti Valley journey.",
				tags: [
					{
						key: "Altitude",
						value: "6260 Feet",
					},
					{
						key: "Meals",
						value: "Breakfast",
					},
				],
				imageURl:require("../media/dusty/routes/spitiroute.jpg")
			},
		],
		keyInfoTabs: {
			
			inclusions: [
				"A-grade accommodations on double sharing basis.",
				"Meals: Dinner & Breakfast (Starting Day 1 Dinner to Day 7 Breakfast) + watebottles during the trip.",
				"RE Classic/Bullet/Himalayan/ Avenger/Xpulse provided with fuel as per your selected package. You will have to pay Rs 5000 (refundable) as a security deposit for Motorcycle on Day 1 which will be refunded at the end of the trip on successful return of the bike in good condition.",
				"Helmets for Rider and Pillion both, though we recommend to bring your own for comfortable fitting",
				"Bonfire at suitable location",
				"Experienced Road Captain on a vehicle",
				"24x7 Breakdown Assistance (Mechanic & support staff)",
				"Back-Up Vehicle specifically for luggage",
				"Inner Line Permits & Environmental Fees",
				"Oxygen Cylinders in case of emergency",
				"Basic Medical Kit",
			],
			exclusions: [
				"Cost of any spare parts consumed during the ride. Any cost arising due to accident of motorbike.",
				"Lunch, snacks, tolls, travel insurance, riding gears other than Helmet.",
				"Any food, travel and accomodation cost arising due to bad health or natural calamities like landslides, road blocks, bad weather etc.",
				"Any cost arising when itinerary changes due to force majeure",
				"Personal expenses such as table drinks, water bottles, alcohol, snacks, telephone calls, room service, laundry, tips to drivers, guides and hotel staff",
				"Motorcycle security deposit of Rs 5000/- per bike (refundable).",
				"Any entry fees for monasteries and monuments or photo/video cameras.",
				"Towing charges of vehicles if left half way during the ride or during accidental cases",
				"Any payment demanded by a driver to ride your bike for some part of the trip if you are unable to ride the bike",
				"Anything not mentioned in tour inclusions",
			],
			paymentPolicy: [
				"An amount of USD 200/- per person to be deposited for slot confirmation",
				"Balance amount to be deposited 45 days before the departure date.",
				"Failure to adhere to 'Payment Policy' can lead to cancellation of the booking without any prior notice.",
			],
			cancellationPolicy: [
				"100% of slot confirmation amount is refunded if trip cancelled within 7 days of booking.",
				"30 days or more before departure: 25% of total cost (Will Be Deducted)",
				"29 - 20 days before departure: 50% of total cost (Will Be Deducted)",
				"Less than 19 days before departure: 100% of total cost (Will Be Deducted)",
			],
			dates: [
				{
					fromDate: "10\u00A0May\u00A02024",
					toDate: "18\u00A0May\u00A02024",
					fromDateDay: "Friday",
					toDateDay: "Saturday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,899/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,649/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A01,899/-",
						},]
				},
				{
					fromDate: "24\u00A0May\u00A02024",
					toDate: "01\u00A0Jun\u00A02024",
					fromDateDay: "Friday",
					toDateDay: "Saturday",
					offPercent: "Few\u00A0Slots",
					saleWord:"",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,899/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "",
							price2: "$\u00A01,649/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "",
							price2: "$\u00A01,899/-",
						},]
				},
				{
					fromDate: "07\u00A0Jun\u00A02024",
					toDate: "15\u00A0Jun\u00A02024",
					fromDateDay: "Wednesday",
					toDateDay: "Saturday",
					offPercent: "$\u00A0200\u00A0off",
					saleWord:"Hurry!",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,899/-",
							price2: "$\u00A01,699/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,649/-",
							price2: "$\u00A01,449/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A01,899/-",
							price2: "$\u00A01,699/-",
						},]
				},
				{
					fromDate: "21\u00A0Jun\u00A02024",
					toDate: "29\u00A0Jun\u00A02024",
					fromDateDay: "Friday",
					toDateDay: "Saturday",
					offPercent: "No\u00A0Slots",
					saleWord:"Sold\u00A0Out",
					pricing: [
						
						{
							package: "One Bike,One Rider",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,899/-",
							price2: "$\u00A01,549/-",
						},
						{
							package: "One Bike,Two Riders",
							transport: "RE 350/500 cc + Fuel",
							price: "$\u00A01,649/-",
							price2: "$\u00A01,299/-",
						},
						{
							package: "Seat in SUV or Pickup",
							transport: "One Exclusive Seat",
							price: "$\u00A01,899/-",
							price2: "$\u00A01,549/-",
						},]
				},
			],
			reachUs: {
				city: "Manali",
				description:
					"Navigating the breathtaking landscapes of Leh, nestled in the heart of the Indian Himalayas, is a journey in itself. Here's your guide on the various ways to reach this enchanting destination: ",
				options: [
					{	icon: (
						<FlightIcon
							style={{
								fontSize: "3rem",
								marginBottom: "1rem",
							}}
						/>
					),
						vehicle: "By Flight",
						description:
							" Fly to Kullu-Manali Airport (Bhuntar Airport) and then proceed to Manali by road. Alternatively, riders can fly to Chandigarh or Delhi and take a bus or drive to Manali.",
					},
					{	icon: (
						<TrainIcon
							style={{
								fontSize: "3rem",
								marginBottom: "1rem",
							}}
						/>
					),
						vehicle: "By Train",
						description:
							"While there isn't a direct train to Manali, riders can take a train to Joginder Nagar, which is the nearest railway station, and then proceed by road to Manali.",
					},
					{	icon: (
						<DirectionsCarIcon
							style={{
								fontSize: "3rem",
								marginBottom: "1rem",
							}}
						/>
					),
						vehicle: "By Car",
						description:
							"Drive to Manali in your own vehicle or rent one for a road trip adventure. Enjoy the flexibility and scenic journey through the picturesque landscapes.",
					},
					{	icon: (
						<DirectionsBusIcon
							style={{
								fontSize: "3rem",
								marginBottom: "1rem",
							}}
						/>
					),
						vehicle: "By Bus",
						description:
							"Numerous intercity and state-run buses connect Manali with major cities. Riders can choose from various bus services offering both budget and luxury options.",
					},
				],
			},
			packingList: [
				{
					item: "Documents",
					details: [
						"4 passport size photographs.",
						"4 Copies of Photo ID proof. (Pan Card is not Valid ID proof)",
						"Valid driving License (Only for rider)",
						"Bike / Car papers",
					],
				},
				{
					item: "Riding Gear",
					details: [
						"Own Full Face Helmet(Recommended)/ Otherwise provided by us",
						"3 Layered Riding Jacket(Recommended)/ Elbow Pads(Available on Rent)",
						"Riding Pants(Recommended) / Knee Pads( Available on Rent)",
						"Riding Gloves(Available on Rent)",
					],
				},
				{
					item: "Shoes and Backpack",
					details: [
						"Riding Shoes(Recommended)/Trekking Shoes",
						"Rucksack Bag(Max 60L)",
					],
				},
				{
					item: "Warm Layers and Clothes",
					details: [
						"Padded Jackets",
						"Windcheater ",
						"Rain coat",
						"Fleece",
						"3-4 Jeans /3-4 Track pants",
						"5 to 6 T-shirt ",
						"2-3 pairs of thermal.",
						"Towels",
						"3-4 Pair of Cotton Socks, 3-4 Woolen socks",
						"Woolen Hand Gloves,Warm head cover, ear covers & Forehead covers ",
					],
				},
				{
					item: "Accessories",
					details: [
						"Sunglasses",
						"Water Bottle",
						"Multi-Plug",
						"Plastic Bags ",
						"Slippers",
					],
				},
				{
					item: "Toiletries",
					details: [
						"Toothbrush & Paste",
						"Toilet Soap/ Hand sanitizer.",
						"Tissue Papers ",
						"Face wash",
						"Moisturizer",
						"Talc powder",
						"Sunscreen",
						"Lip Balm",
						"Comb/ Brush",
					],
				},
				{
					item: "Medicines",
					details: [
						"Ensure you bring along your personal medical kit to address any potential ailments during your journey. While we do provide basic medications for emergencies, having your own supplies ensures you're prepared for any medical needs that may arise.",
					],
				},
			],
		},
		faqs: [
			{
				question: "What vehicles are provided for luggage carriers?",
				answer: "We offer Mahindra Bolero, Imperio, Scorpio Getaway, and iSuzu for luggage carriers.",
			},
			{
				question: "What riding gear is essential for the Ladakh trip?",
				answer: "Essential riding gear includes a riding jacket, riding pants, boots, gloves, and a full-face helmet. Refer the packing list for complete details",
			},
			{
				question: "Is it necessary to carry thermal wear?",
				answer: "Yes, Ladakh experiences extremely cold temperatures, and carrying thermal wear is recommended as temperatures can drop below freezing.",
			},
			{
				question: "What original documents must be carried?",
				answer: "Original documents required are Covid Vaccination Certificate, Driving Licence, Aadhar Card, and for self-bikers - RC, Insurance, and Pollution Certificate.",
			},
			{
				question: "Is there a risk of oxygen shortage during the trip?",
				answer: `Yes, riding at altitudes above 10,000 feet poses a risk of difficulty in breathing due to low atmospheric oxygen levels. The primary cause of AMS (Acute Mountain Sickness) is dehydration, often exacerbated by riders not consuming enough water. To mitigate AMS, follow these steps: 
                    1. Stay hydrated by drinking water mixed with Electral/ORS/Glucose.
                    2. Avoid pushing yourself too hard; take it slow to acclimate.
                    3. If feeling dizzy or uncomfortable, stop and take Diamox, which begins working within an hour.
                    4. If experiencing dizziness, seek oxygen assistance from the road captain.`,
			},
			{
				question:
					"Which mobile network is accessible in Spiti Valley Trip?",
				answer: "Postpaid Jio, Airtel, and BSNL networks work well in Spiti Valley.",
			},
		],
	},
};

export const packageList = [
	{
		path: "/packages/leh2leh",
		packageName: "Leh to Leh",
	},
	{
		path: "/packages/manali2leh",
		packageName: "Manali to Leh",
	},
	{
		path: "/packages/manali2srinagar",
		packageName: "Manali to Srinagar",
	},
	{
		path: "/packages/srinagar2manali",
		packageName: "Srinagar to Manali",
	},
	{
		path: "/packages/spiti",
		packageName: "Spiti",
	},
];

export const packageInfoList = [
	{
		place: "Chennai",
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Praesentium repellat repudiandae iste veritatis cumque vitae!",
		img: himachal,
		price: "4000-5000 INR",
		rating: 4.9,
	},
	{
		place: "Delhi",
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Praesentium repellat repudiandae iste veritatis cumque vitae!",
		img: goa,
		price: "4000-5000 INR",
		rating: 4.9,
	},
	{
		place: "Goa",
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Praesentium repellat repudiandae iste veritatis cumque vitae!",
		img: kerala,
		price: "4000-5000 INR",
		rating: 4.9,
	},
	{
		place: "Gujrat",
		info: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Praesentium repellat repudiandae iste veritatis cumque vitae!",
		img: rajasthan,
		price: "4000-5000 INR",
		rating: 4.9,
	},
];
