import React from "react";
import "./ChooseUs.css"; // Your CSS file for styling
// import { faXmark } from '@fortawesome/pro-solid-svg-icons';

// Icon component imports
// import CameraIcon from './icons/CameraIcon';
// import PlaneIcon from './icons/PlaneIcon';
// import MotorcycleIcon from './icons/MotorcycleIcon';
// import RefundIcon from './icons/RefundIcon';
// import CreditsIcon from './icons/CreditsIcon';
// import ShieldIcon from './icons/ShieldIcon';

const services = [
	{
		id: 1,
		title: "In-House Photographer",
		description:
			"Professional photographer to capture your priceless moments. Be sure to get those stunning DPs and candids.",
		icon: "fas fa-camera",
	},
	{
		id: 2,
		title: "Best Flight Deals",
		description:
			"We partner with major airlines to get you preferred (unpublished)  prices for your trips. Book with us and save more.",
		icon: "fas fa-plane-departure",
	},
	{
		id: 3,
		title: "Bike Riding Workshop",
		description:
			"Riding for the first time? No Problem. You’ll get access to free bike riding workshops from our trip captains before your trip.",
		icon: "fas fa-motorcycle",
	},
	{
		id: 4,
		title: "100% Refund",
		description:
			"Book with peace of mind. Get 100% refund of booking amount withing 7 days.",
		icon: "fas fa-rupee-sign",
	},
	{
		id: 5,
		title: "No Cost EMI",
		description:
			"Book your trips stress-free with our no-cost EMI payment option on major credit cards. Book now and pay flexibly.",
		icon: "fas fa-credit-card",
	},
	{
		id: 6,
		title: " Premium Service",
		description:
			"Meticulously maintained bikes guarantee exhilarating rides through rugged terrains, while carefully chosen accommodations provide post-adventure comfort.",
		icon: "fas fa-medal",
	},
];

const ServiceCard = ({ title, description, icon }) => {
	return (
		<div className="service-card">
			<div className="icon">
				<i className={icon}></i>
			</div>
			<h3 className="title-choose">{title}</h3>
			<p className="description">{description}</p>
		</div>
	);
};

export const WhyChooseUs = () => {
	return (
		<section className="why-choose-us">
			<h2 className="section-title-choose">Why Choose Us?</h2>
			<div className="services-container">
				{services.map((service) => (
					<ServiceCard
						key={service.id}
						title={service.title}
						description={service.description}
						icon={service.icon}
					/>
				))}
			</div>
		</section>
	);
};
